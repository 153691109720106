import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useLibraryContext } from '../context/LibraryContext';
import { Availability, AvailabilityStatus } from '../domain/product';
import { customize } from '../utils/localizations/customize';

interface IAvailabilityInfoProps {
    readonly availability: Availability;
    readonly className?: string;
}

export const AvailabilityInfo: FC<IAvailabilityInfoProps> = ({ availability, className }) => {
    const { status, nextAvailableDate } = availability;

    const { t } = useTranslation();
    const { timezone } = useLibraryContext();

    const isOnLoan = status === AvailabilityStatus.OnLoan;
    const isNew = status === AvailabilityStatus.New;

    const isReservable = (isOnLoan || isNew) && nextAvailableDate;

    return isReservable ? (
        <div className={classNames('availability', className)}>
            {customize(
                isOnLoan
                    ? t('defaults.onLoan', { value: nextAvailableDate, timeZone: timezone })
                    : t('defaults.newReserveNow', { value: nextAvailableDate, timeZone: timezone })
            )}
        </div>
    ) : null;
};
