import Link from 'next/link';
import { ComponentProps, FC } from 'react';

import { AgeCategoryType } from '../../domain/ageCategory';
import { useScopedPath } from '../../hooks/utils/useScopedPath';
import { LoanFormatType } from '../../utils/domain/loanFormat';

type ScopedLinkProps = ComponentProps<'a'> & {
    /**
     * The path suffix for the scoped link.
     */
    readonly path: string;

    /**
     * If set, takes precedence over the loan format of the ScopeContext.
     */
    readonly overrideLoanFormat?: LoanFormatType;

    /**
     * If set, takes precedence over the age category of the ScopeContext.
     */
    readonly overrideAgeCategory?: AgeCategoryType;
};

/**
 * Creates a link pointing to a page that might be prefixed with a loan format and age category.
 */
export const ScopedLink: FC<ScopedLinkProps> = ({
    path,
    overrideLoanFormat,
    overrideAgeCategory,
    children,
    ...props
}) => {
    const href = useScopedPath(path, { loanFormat: overrideLoanFormat, ageCategory: overrideAgeCategory });

    return (
        <Link href={href} legacyBehavior>
            <a {...props}>{children}</a>
        </Link>
    );
};
