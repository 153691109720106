import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getProductActionProductData } from './getProductActionProductData';
import { useProductActionDispatch } from './ProductActionContext';
import { InitialActionType, ProductActionProductData } from './ProductActionReducer';
import { useLoanData } from './useLoanData';
import { useSignedOutUser } from './useSignedOutUser';
import { useProductDetails } from '../../api/product-details/useProductDetails';
import { useSubscription } from '../../api/subscriptions/useSubscription';
import { useUserRole } from '../../context/AuthenticationContext';
import { AvailabilityStatus } from '../../domain/product';
import { loanFormatToGildClass, LoanFormatType } from '../../utils/domain/loanFormat';
import { i18nLoanFormatMap } from '../../utils/localizations/i18nextMaps';
import { getFilled, Icon } from '../Icon';

type Props = {
    readonly product: ProductActionProductData;
    readonly look: 'filled' | 'outline' | 'action';
    readonly isLarge?: boolean;
    readonly ariaDescribedBy?: string;
};

export const ProductActionButton: FC<Props> = ({ product, look, isLarge = false, ariaDescribedBy }) => {
    const { t } = useTranslation();

    const { isFullUser } = useUserRole();

    const { isLoading: isLoadingSubscription, refetch: fetchSubscription } = useSubscription({
        rootProductId: product.rootProductId ?? product.productId,
        enabled: false
    });

    // FIXME: The ShortProduct would be sufficient here, but we don't have an endpoint to fetch a single ShortProduct
    const { isLoading: isLoadingLatestAvailableIssue, refetch: fetchLatestAvailableIssue } = useProductDetails({
        productId: product.latestAvailableIssueId ?? product.productId,
        enabled: false
    });

    const isLoading = isLoadingSubscription || isLoadingLatestAvailableIssue;

    const loanData = useLoanData(product);

    const dispatchProductAction = useProductActionDispatch();
    const handleSignedOutUser = useSignedOutUser();

    const icon = getFilled(loanFormatToGildClass(product.loanFormat));
    const iconLabel = t(`defaults.singular.${i18nLoanFormatMap[product.loanFormat]}`);

    const handleBorrow = () => {
        if (isFullUser) {
            dispatchProductAction({
                type: InitialActionType.borrowIntended,
                product,
                loanData
            });
        } else {
            handleSignedOutUser({
                productAction: InitialActionType.borrowIntended,
                productId: product.productId,
                loanFormat: product.loanFormat
            });
        }
    };

    const handleBorrowSubscribe = async () => {
        if (isFullUser) {
            const { data: subscription } = await fetchSubscription();
            const { data: latestAvailableIssue } = await fetchLatestAvailableIssue();
            if (latestAvailableIssue)
                dispatchProductAction({
                    type: InitialActionType.borrowSubscribeIntended,
                    product: getProductActionProductData(latestAvailableIssue),
                    loanData: { ...loanData, subscription }
                });
        } else {
            handleSignedOutUser({
                productId: product.latestAvailableIssueId ?? product.productId,
                productAction: InitialActionType.borrowSubscribeIntended,
                loanFormat: LoanFormatType.eMagazineIssues
            });
        }
    };

    const handleReserve = () => {
        if (isFullUser) {
            dispatchProductAction({ type: InitialActionType.reserveIntended, product, loanData });
        } else {
            handleSignedOutUser({
                productId: product.productId,
                productAction: InitialActionType.reserveIntended,
                loanFormat: product.loanFormat
            });
        }
    };

    const isSubscribable =
        product.loanFormat === LoanFormatType.eMagazines || product.loanFormat === LoanFormatType.eMagazineIssues;

    switch (product.availabilityStatus) {
        case AvailabilityStatus.Available:
            return (
                <button
                    onClick={() => (isSubscribable ? handleBorrowSubscribe() : handleBorrow())}
                    disabled={isLoading}
                    className={classNames({
                        'button-positive': look === 'filled',
                        'button-neutral icon-positive': look === 'outline',
                        action: look === 'action',
                        'size-large': isLarge
                    })}
                    aria-describedby={ariaDescribedBy}
                >
                    {t('defaults.button.borrow')} <Icon icon={icon} label={iconLabel} />
                </button>
            );

        case AvailabilityStatus.OnLoan:
        case AvailabilityStatus.New:
            return (
                <button
                    onClick={handleReserve}
                    disabled={isLoading}
                    className={classNames({
                        'button-warning': look === 'filled',
                        'button-neutral icon-warning': look === 'outline',
                        action: look === 'action',
                        'size-large': isLarge
                    })}
                    aria-describedby={ariaDescribedBy}
                >
                    {t('defaults.button.reserve')} <Icon icon={icon} label={iconLabel} />
                </button>
            );

        case AvailabilityStatus.Unavailable:
        default:
            return (
                <button
                    disabled
                    className={classNames('button-neutral', {
                        action: look === 'action',
                        'size-large': isLarge
                    })}
                    aria-describedby={ariaDescribedBy}
                >
                    {t('defaults.button.unavailable')} <Icon icon={icon} label={iconLabel} />
                </button>
            );
    }
};
