import { GetServerSideProps, NextPage } from 'next';

import { prefetchCampaignBanners } from '../api/banners/useCampaignBanners';
import { prefetchFeaturedBanners } from '../api/banners/useFeaturedBanners';
import { prefetchProductGroupProducts } from '../api/product-group/useProductGroupProducts';
import { PageSlotsResponse, ProductGroupPageSlotResponse, SlotType } from '../api/productGroup';
import { BasicLayout } from '../components/layout/BasicLayout';
import { HomeHeader } from '../components/layout/header/Header';
import { DEFAULT_PRODUCT_GROUP_PARAMS } from '../components/PageProductGroup';
import { PageTitleShareMeta } from '../components/PageTitleShareMeta';
import { getProductGroupFetchParams } from '../hooks/getters/useProductListParams';
import { FeaturedPage } from '../page-components/featured/FeaturedPage';
import { Protected } from '../page-components/Protected';
import { safeDehydrate } from '../setup/react-query/safeDehydrate';
import { initSSR } from '../setup/ssr';
import { UserPermission } from '../utils/domain/authorization';
import { aggregateTotalCount } from '../utils/domain/loanFormat';
import LoggingUtils from '../utils/logging/LoggingUtils';
import { NoSsr } from '../utils/nextjs/NoSsr';

type Props = {
    readonly slots: PageSlotsResponse;
};

const Discover: NextPage<Props> = NoSsr(({ slots }) => {
    return (
        <BasicLayout header={<HomeHeader />}>
            <Protected required={UserPermission.ACCESS_GUEST}>
                <PageTitleShareMeta />
                <FeaturedPage slots={slots} loanFormat={undefined} />
            </Protected>
        </BasicLayout>
    );
});

export const getServerSideProps: GetServerSideProps<Props> = async context => {
    try {
        const { ssrClient, queryClient, siteParams, siteContext } = await initSSR(context);

        const page = siteContext.navigation?.defaultCollection.home.featuredPage;

        if (!page || !page.slots) {
            LoggingUtils.logSSRError(`failed to render discover page, page not found or has no slots`);
            return { notFound: true };
        }

        // PP-1679: hide genre banners in discover page for now
        const filteredSlots = page.slots
            .filter(slot => slot.slotType !== SlotType.banner || slot.bannerSlotType !== 'GENRE_BANNER')
            .filter(slot => slot.slotType !== SlotType.productGroup || aggregateTotalCount(slot.totalCount) > 0);

        const productGroupIds = filteredSlots
            .filter(s => s.slotType === SlotType.productGroup)
            .map(s => (s as ProductGroupPageSlotResponse).productGroupId);

        const pagination = { offset: 0, limit: 50 };

        // TODO Add caching to all this (either here or in the backend)
        await Promise.all<unknown>([
            prefetchCampaignBanners(ssrClient, queryClient, { pagination }, siteParams),
            prefetchFeaturedBanners(ssrClient, queryClient, {}, siteParams),
            ...productGroupIds.map(productGroupId =>
                prefetchProductGroupProducts(
                    ssrClient,
                    queryClient,
                    {
                        productGroupId,
                        params: getProductGroupFetchParams({ params: DEFAULT_PRODUCT_GROUP_PARAMS })
                    },
                    siteParams
                )
            )
        ]);

        return {
            props: {
                slots: filteredSlots,
                dehydratedState: safeDehydrate(queryClient)
            }
        };
    } catch (error) {
        LoggingUtils.logSSRError('failed to render discover page', error);

        return {
            props: {
                slots: []
            }
        };
    }
};

export default Discover;
