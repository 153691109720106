import { useTranslation } from 'react-i18next';

import { TranslationI18nType } from '../../setup/i18n';

export const useTranslatedProductGroup = (title?: string) => {
    const { t } = useTranslation();

    if (!title) return;

    const titleKeyNormalized = title
        .replace(/[^0-9a-zA-Z]/g, ' ') // replace everything except numbers and letters by spaces 'Q&A' -> 'q a'
        .replace(/ +/g, ' ') // replace multiple spaces by one: '   ' -> ' '
        .trim() // remove leading and trailing spaces
        .replace(/ /g, '_') // use underscores instead of spaces
        .toLowerCase() as keyof TranslationI18nType['product_group_titles'];

    return t(`product_group_titles.${titleKeyNormalized}`, { defaultValue: title });
};
