import { FC, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductTileContent } from './ProductTileContent';
import { ProductTileAction, ProductTileInformation } from './ProductTileContentComponents';
import { useLoanStatus } from '../../context/LoanStatusContext';
import { ShortProduct } from '../../domain/product';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { AvailabilityInfo } from '../AvailabilityInfo';
import { MyListButton } from '../MyListButton';
import { getProductActionProductData } from '../product-action/getProductActionProductData';
import { ProductActionButton } from '../product-action/ProductActionButton';

type StandardProductTileContentProps = {
    readonly product: ShortProduct;
};

export const StandardProductTileContent: FC<StandardProductTileContentProps> = ({ product }) => {
    const { t } = useTranslation();
    const productId = product.availability.latestAvailableIssueId ?? product.productId;
    const titleId = useId();
    const loanStatus = useLoanStatus({ productId });
    const isMagazineIssueCurrentlyOnLoan =
        loanStatus.active && product.formatSpecifics.loanFormat === LoanFormatType.eMagazineIssues;

    return (
        <ProductTileContent product={product}>
            <div className="details">
                <ProductTileAction>
                    <MyListButton productId={productId} ariaDescribedBy={titleId} />
                    {isMagazineIssueCurrentlyOnLoan ? (
                        <button disabled={true} className="button-positive">
                            {t('defaults.button.reading_now')}
                        </button>
                    ) : (
                        <ProductActionButton
                            product={getProductActionProductData(product)}
                            look="outline"
                            ariaDescribedBy={titleId}
                        />
                    )}
                </ProductTileAction>
                <ProductTileInformation titleId={titleId}>
                    <AvailabilityInfo availability={product.availability} />
                </ProductTileInformation>
            </div>
        </ProductTileContent>
    );
};
