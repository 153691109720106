import { ComponentProps, FC, PropsWithChildren } from 'react';

import { ScopedLink } from './ScopedLink';
import { GenreId } from '../../api/genres';
import { pathGenre } from '../../utils/routes/paths';

type GenreListPageLinkProps = ComponentProps<'a'> &
    PropsWithChildren<{
        readonly parentGenreName?: string;
        readonly genreId: GenreId;
        readonly genreName: string;
    }>;

export const GenreListPageLink: FC<GenreListPageLinkProps> = ({
    parentGenreName,
    genreId,
    genreName,
    children,
    ...props
}) => {
    const genreSlug = parentGenreName ? `${parentGenreName}-${genreName}` : genreName;
    return (
        <ScopedLink path={pathGenre(genreId, genreSlug)} {...props}>
            {children}
        </ScopedLink>
    );
};
