import { useRouter } from 'next/router';

import { InitialActionType } from './ProductActionReducer';
import { useRedirect } from '../../hooks/events/useRedirect';
import { loginRoutePath } from '../../pages/login/[[...any]]';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { stringifyQuery } from '../../utils/routes/queryString';
import { extractBasePath, filterNonProductListQueryParams } from '../../utils/routes/routing';

type Props = {
    productAction: InitialActionType;
    productId: string;
    loanFormat: LoanFormatType;
};

export const useSignedOutUser = () => {
    const router = useRouter();
    const redirectTo = useRedirect();

    return ({ productAction, productId, loanFormat }: Props) => {
        const newQuery = filterNonProductListQueryParams(router.query);
        newQuery['action'] = productAction;
        newQuery['productId'] = productId;
        newQuery['lf'] = loanFormat;
        const newUrl = `${extractBasePath(router.asPath)}?${stringifyQuery(newQuery)}`;
        void redirectTo(loginRoutePath({ redirectTo: newUrl }));
    };
};
