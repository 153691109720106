import { AgeCategorySlugs, LoanFormatSlugs } from '../../components/layout/header/Navigation';
import { useScopeContext } from '../../context/ScopeContext';
import { AgeCategoryType } from '../../domain/ageCategory';
import { LoanFormatType } from '../../utils/domain/loanFormat';

type Overrides = {
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};

/**
 * Creates a scoped path, pointing to a page prefixed by an optional loan format and age category.
 */
export function useScopedPath(path: string, overrides?: Overrides): string {
    const { loanFormat: targetLoanFormat, ageCategory } = useScopeContext();
    const loanFormat =
        targetLoanFormat === LoanFormatType.eMagazineIssues ? LoanFormatType.eMagazines : targetLoanFormat;
    return createScopedPath(path, overrides?.loanFormat ?? loanFormat, overrides?.ageCategory ?? ageCategory);
}

export function createScopedPath(path: string, loanFormat?: LoanFormatType, ageCategory?: AgeCategoryType): string {
    return (
        (loanFormat ? `/${LoanFormatSlugs[loanFormat]}` : '') +
        (loanFormat && ageCategory && ageCategory !== AgeCategoryType.allAges
            ? `/${AgeCategorySlugs[ageCategory]}`
            : '') +
        path
    );
}
